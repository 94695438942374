import React from "react";
import "./Contact.css";
import { useRef } from "react";
import emailjs from '@emailjs/browser';
import whatsapp from "./images/Whatsapp.svg";
import email from "./images/mail.svg";
import instagram from "./images/instagram.svg";

const Contact = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_1fqgua5",
        "template_fikyurs",
        form.current,
        "On2oxqLbUhLjK89Ff"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  return (
    <section id="Contact" className="ContactWrap">
     <div className="ContactTitleContainer">
     <h3 className="H2Text">GET IN TOUCH!</h3>
          <p >
            We are a multidiscplinary team made up of highly quialificated
            designers, engineers and jewelers who will always be ready to bring
            your ideas to life.
          </p>
        </div>
      <div className="ContentContainer">
       
        <div className="ContactContainer">
          <div className="cCardsContainer">
            
            <div className="ContactCard">
              <a
                href="https://wa.me/573052259330?text=Hello%20I'm%20interested%20in%20your%203D%20services"
                className="Whatsappli"
                target="_blank"
              >
                <img
                  src={whatsapp}
                  alt="Whatsapp icon"
                  className="contactIcon"
                />
                <h4 >whatsapp 3D Alquimista</h4>
              </a>
            </div>
            <div className="ContactCard">
              <a
                href="https://wa.me/573053600565?text=Hola%20estoy%20interezad@%20en%20sus%203D%20servicios"
                className="Whatsappli"
                target="_blank"
              >
                <img
                  src={whatsapp}
                  alt="Whatsapp icon"
                  className="contactIcon"
                />
                <h4 >whatsapp Alquimista estudio</h4>
              </a>
            </div>

            <div className="MailWrap">
              <div className="mailContentWrap">
                <img src={email} alt="Email icon" className="contactIcon" />
                <h4 >3dalquimista@gmail.com</h4>
              </div>
            </div>

            <div className="ContactCard">
              <a
                href="https://www.instagram.com/3d.alquimista/"
                className="Whatsappli"
                target="_blank"
              >
                <img
                  src={instagram}
                  alt="instagram icon"
                  className="contactIcon"
                />
                <h4 className="H4Text">@3d.alquimista</h4>
              </a>
            </div>
          </div>

          <form ref={form} onSubmit={sendEmail} className="contactForm">
            <input type="text" name="name" placeholder="Your Name" required autocomplete="given-name" />
            <input
            id="email"
              type="email"
              name="email"
              placeholder="Your Email"
              autocomplete="email"
              required
            />
            <textarea
              name="message"
              id="message"
              placeholder="Your Message"
              cols="30"
              rows="10"
              required
            ></textarea>
            <button type="submit" className="btn">
              Send
            </button>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;

